import { createRouter,createWebHistory} from "vue-router";
import {get_token} from '../services/requests'
const login = () => import("../components/Login")
const home = () => import("../components/Home")

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: home,
    meta:{
      title: "Dashboard"
    }
  },
  {
    path: "/",
    name: "login",
    component: login,
    meta:{
      title: "Login"
    }
  }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})



router.beforeEach((to, from, next) => {
  var token = get_token()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(!token){
    if(to.path == '/'){
      next()
    }
    else{
      console.log("2")
      next('/')
    }
  }
  else{
    if(to.path == '/' && token){
      next('/dashboard')
    }else{
      next()
    }
  }

})

export default router;