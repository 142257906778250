<template>
  <router-view />
</template>


<script>
  import { useRouter } from 'vue-router'
  export default {
    setup () {
      const router = useRouter()
      const LoginPage = (() => {
        router.push({
          name: 'login'
        })
      })
      return {
        LoginPage
      }
    },
  }

    
</script>



