import {
    Buffer
} from "buffer"

export function b64encode ( data ) {
    return Buffer.from(JSON.stringify(data)).toString('base64')
}

export function b64decode ( data ) {
    return JSON.parse(Buffer.from(data, 'base64').toString('utf-8'))
}


export function get_ts () {
    return new Date().getTime();
}